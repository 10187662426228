<template>
  <div>
    Nominate
  </div>
</template>

<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'Nominate',
  components: {
  },
  props: {
  },
  data () {
    return {}
  },
  computed: {
  },
  methods: {}
}
</script>
