<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <MyClub />
    </template>

    <template #rightPanel>
      <SideMenu />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import SideMenu from '@/components/navbar/sideMenu.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import MyClub from '@/components/myClub/myClub.vue'

export default {
  name: 'MyClubContainer',
  components: {
    MainContainer, SideMenu, MyClub
  },
  computed: {
    ...mapGetters(['userData'])
  }
}
</script>
