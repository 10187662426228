<template>
  <div style="height: 100%;">
    <div
      class="text-center primary-colour mx-auto pb-2 pb-md-3"
      style="position: sticky;top: 0; width: 100%; background: var(--bg-color); font-size: clamp(0.8rem, 2vw, 1rem)"
    >
      Clubs should receive payouts between the 1st and 5th of each month.
    </div>

    <BTable
      id="payouts-table"
      class="text-left text-center payoutsTable"
      head-variant="dark"
      :items="payouts"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      responsive
      :striped="true"
      hover
      small
      sort-icon-left
      auto-width
    >
      <template v-slot:cell(month)="data">
        <div class="adminTableRow text-nowrap">
          {{ new Date(data.item.Month).toLocaleString('default', { year: 'numeric', month: 'short' }) }}
        </div>
      </template>
      <template v-slot:cell(playercount)="data">
        <div class="adminTableRow">
          {{ data.item.PlayerCount }} <span v-if="windowWidth < 577">({{ data.item.HeroCount }})</span>
        </div>
      </template>
      <template v-if="windowWidth > 576" v-slot:cell(herocount)="data">
        <div class="adminTableRow">
          {{ data.item.HeroCount }}
        </div>
      </template>
      <template v-slot:cell(amount)="data">
        <div class="adminTableRow">
          £{{ data.item.Amount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(ispaid)="data">
        <div class="adminTableRow">
          <!-- PAID -->
          <span v-if="data.item.IsPaid" class="payoutAdminTableStatus paid">
            <i class="fa fa-check" />
            PAID
          </span>
          <!-- DUE -->
          <span
            v-else-if="paymentIsDue(data.item.Month)"
            v-show="data.item.Amount > 0"
            class="payoutAdminTableStatus due"
          >
            <i class="fa fa-spinner" />
            DUE
          </span>
          <!-- ONGOING - NOT PAID BUT DATE OF PAYMENT IS IN THE FUTURE -->
          <span
            v-else
            class="payoutAdminTableStatus ongoing"
          >
            ONGOING
          </span>
          <!-- DUE -->
        </div>
      </template>
    </BTable>
    <!-- <div v-else class="mt-4">There are no transaction records to show</div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import {PAYMENT_IS_DUE} from '@/common/utils'

export default {
  name: 'Payouts',
  components: { },
  data () {
    return {
      currentPage: 1,
      payouts: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'Month', label: 'Payment Month' },
          { key: 'PlayerCount', label: 'Players', sortable: true },
          { key: 'HeroCount', label: 'Hero Count' },
          { key: 'Amount', label: 'Amount' },
          { key: 'IsPaid', label: 'Status' }
        ]
      } else {
        return [
          { key: 'Month', label: 'Month' },
          { key: 'PlayerCount', label: 'Players', sortable: true },
          { key: 'Amount', label: '£' },
          { key: 'IsPaid', label: 'Status' }
        ]
      }
    },
    perPage () {
      return 12
    },
    rows () {
      return 12
    }
  },
  mounted () {
    this.getPaymentsTableData()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getPaymentsTableData () {
      this.$emit('loading', true)
      try {
        const res = await adminService.getPaymentsTableData(this.userData.access_token)
        this.payouts = res.data
      } catch (err) {
        console.log(err)
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.$emit('loading', false)
      }, 500)
    },
    paymentIsDue(paymentDate) {
      return PAYMENT_IS_DUE(paymentDate)
    }
  }
}
</script>

<style>
.payoutAdminTableStatus.paid {
  color: var(--pr-color);
}
.payoutAdminTableStatus.due {
  color: red;
}
.payoutsTable * {
  font-size: clamp(0.7rem, 2vw, 1.2rem) !important;
}
</style>
