<template>
  <BTable
    id="players-table"
    class="text-left text-center adminPlayersTable"
    head-variant="dark"
    :items="payouts"
    :per-page="perPage"
    :current-page="currentPage"
    :fields="fields"
    responsive
    :striped="true"
    hover
    small
    sort-icon-left
    auto-width
  >
    <template v-slot:cell(firstname)="data">
      <div class="adminTableRow text-nowrap">
        {{ data.item.FirstName }} {{ data.item.LastName }}
      </div>
    </template>
    <template v-slot:cell(emailaddress)="data">
      <div class="adminTableRow text-nowrap">
        {{ data.item.EmailAddress }}
      </div>
    </template>
    <template v-slot:cell(livecardcount)="data">
      <div class="adminTableRow text-nowrap">
        {{ data.item.LiveCardCount }}
      </div>
    </template>
  </BTable>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'Players',
  components: { },
  data () {
    return {
      currentPage: 1,
      payouts: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'FirstName', label: 'Player' },
          { key: 'EmailAddress', label: 'Email Address', sortable: true },
          { key: 'LiveCardCount', label: 'Live Heroes' }
        ]
      } else {
        return [
          { key: 'FirstName', label: 'Player' },
          { key: 'EmailAddress', label: 'Email Address', sortable: true },
          { key: 'LiveCardCount', label: 'Heroes' }
        ]
      }
    },
    perPage () {
      return this.payouts.length
    },
    rows () {
      return this.payouts.length
    }
  },
  mounted () {
    this.getClubAdminPlayerList()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getClubAdminPlayerList () {
      this.$emit('loading', true)
      try {
        const res = await adminService.getClubAdminPlayerList(this.userData.access_token)
        this.payouts = res.data != null ? res.data : []
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.$emit('loading', false)
      }, 500)
    }
  }
}
</script>

<style>
.adminPlayersTable * {
  font-size: clamp(0.7rem, 2vw, 1.2rem) !important;
}
</style>
